import { put } from 'redux-saga/effects';
import { SET_LOADING } from '../ui/actions';
import Service from '@src/api/api';
import { SET_ERROR_OBJECT } from '../error/actions';
import { ClaimHistoryModel } from '@src/api/models/Claim';
// import ClaimSaveModel from '@src/api/models/ClaimSaveModel';
import { GET_ACCOUNT } from '../auth/actions';

export const CLAIM_ADD = 'CLAIM_ADD';
export const GET_CLAIM_HISTORY = 'GET_CLAIM_HISTORY';
export const SET_CLAIM_HISTORY = 'SET_CLAIM_HISTORY';

export const RESET_CLAIM = 'RESET_CLAIM';
export const SAVE_CLAIM_FORM = 'SAVE_CLAIM_FORM';
export const UPDATE_CLAIM_INVOICE = 'UPDATE_CLAIM_INVOICE';
export const SET_CLAIM_NUMBER = 'SET_CLAIM_NUMBER';
export const UPLOAD_FILE_INOVICE = 'UPLOAD_FILE_INOVICE';
export const UPLOAD_FILE_QUOTATION = 'UPLOAD_FILE_QUOTATION';
export const UPLOAD_FILE_REPAIR_INOVICE = 'UPLOAD_FILE_REPAIR_INOVICE';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const SET_COST_DETAIL = 'SET_COST_DETAIL';

export function* claimHistory() {
    const contractNumber = sessionStorage.getItem('contractNumber') as string;

    yield put({ type: SET_LOADING, val: true });
    try {
        const response = yield Service.claimList(contractNumber);
        const claimArray: ClaimHistoryModel[] = [];
        if (response.status === 200) {
            if (response.data._embedded) {
                response.data._embedded.claims.forEach((item) => {
                    const claimModel = new ClaimHistoryModel(item);
                    claimArray.push(claimModel);
                });
                yield put({ type: SET_CLAIM_HISTORY, val: claimArray });
            }
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: error?.response?.status } });
    }
    yield put({ type: SET_LOADING, val: false });
}
export function* claimAdd(data) {
    const { val } = data;
    console.log('DATA', data);
    const payLoad = new FormData();
    yield put({ type: SET_LOADING, val: true });
    const partnerId = sessionStorage.getItem('partnerId') as string;
    const contractNumber = sessionStorage.getItem('contractNumber') as string;
    // const deviceData = JSON.parse(sessionStorage.getItem('device') as string);
    // const defaultDamage = JSON.parse(sessionStorage.getItem('defaultDamage') as string);
    // const userData = JSON.parse(sessionStorage.getItem('contract') as string);
    const claim: any = {
        claim: {
            partnerId: partnerId,
            claimType: 'M',
            contractNumber: contractNumber,
            breakdownDate: data?.val?.breakdown_date,
            claimDescription: data?.val?.clm_description,
            // damagePlace: 'Belgium',
            requestOrigin: 'microsite',
            // clm_status: '30',
            // user_name: userData?.customerName,
        },
    };
    payLoad.append(
        'claim',
        new Blob([JSON.stringify(claim)], {
            type: 'application/json',
        })
    );
    if (val?.fileInvoice?.file) {
        payLoad.append('fileRequest', val?.fileInvoice?.file);
    }
    if (val?.fileQuotation?.file) {
        payLoad.append('fileRequest', val?.fileQuotation?.file);
    }
    if (val?.fileRepairInvoice?.file) {
        payLoad.append('fileRequest', val?.fileRepairInvoice?.file);
    }
    // const payLoad = new ClaimSaveModel(claim, userData, deviceData, defaultDamage, []);
    // const payLoad = new ClaimSaveModel(claim, userData, deviceData, defaultDamage, data?.val);

    try {
        const response = yield Service.claimAdd(payLoad);
        if (response.status === 200) {
            yield put({ type: SET_CLAIM_NUMBER, val: response?.data?.claimId });
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
            data.val.claimId = response?.data?.claimId;
            // data.val.claimCostDetails[0].clm_id = response?.data?.claim?.clm_id;
            // claim.clm_id = response?.data?.claim?.clm_id;
            // console.log('CLAIM', claim);
            // const payLoad = new ClaimSaveModel(claim, userData, deviceData, defaultDamage, data?.val);
            // yield Service.claimAdd(payLoad);
            yield put({ type: UPDATE_ACCOUNT, val: val });
            yield put({ type: SET_COST_DETAIL, val: val });

            // if (val?.fileInvoice) {
            //     yield put({ type: UPLOAD_FILE_INOVICE, val: val });
            // }
            // if (val?.fileQuotation) {
            //     yield put({ type: UPLOAD_FILE_QUOTATION, val: val });
            // }
            // if (val?.fileRepairInvoice) {
            //     yield put({ type: UPLOAD_FILE_REPAIR_INOVICE, val: val });
            // }
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: error?.response?.status } });

        // yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
    }

    yield put({ type: SET_LOADING, val: false });
}
export function* updateClaimInvoice(data) {
    yield put({ type: SET_LOADING, val: true });
    const { val } = data;

    try {
        const response = yield Service.updateClaimInvoice(val);
        if (response.status === 200) {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
            yield put({ type: GET_CLAIM_HISTORY });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: true });
    }
}
export function* setCostDetail(data) {
    yield put({ type: SET_LOADING, val: true });
    const { val } = data;
    const partnerId = sessionStorage.getItem('partnerId') as string;
    const payLoad = {
        typeId: 4,
        tentativeValue: val?.invoice_amount.replace(',', '.'),
        invoiceDate: val?.invoice_date,
    };

    try {
        const response = yield Service.costDetailAdd(partnerId, val?.claimId, payLoad);
        if (response.status === 201) {
            // yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
            yield put({ type: GET_CLAIM_HISTORY });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: true });
    }
}
export function* updateAccount(data) {
    yield put({ type: SET_LOADING, val: true });
    const { val } = data;
    const partnerId = sessionStorage.getItem('partnerId') as string;
    const contractNumber = sessionStorage.getItem('contractNumber') as string;
    const payLoad = [
        {
            action: 'UPDATE',
            field: 'ibanNumber',
            value: val?.bank_account,
        },
    ];

    try {
        const response = yield Service.updateAccount(partnerId, contractNumber, payLoad);
        sessionStorage.setItem('ibanNumber', JSON.stringify(val?.bank_account));
        yield put({ type: GET_ACCOUNT, val: true });
    } catch (error) {
        yield put({
            type: 'SET_ERROR_OBJECT',
            val: {
                dictionaryObject: 'verify_errors',
                errorCode: error?.response?.status,
                debugMessage: error?.response?.data?.message,
            },
        });
    } finally {
        yield put({ type: SET_LOADING, val: true });
    }
}
export function* uploadFileInvoice(data) {
    yield put({ type: SET_LOADING, val: true });
    const { val } = data;
    const partnerId = sessionStorage.getItem('partnerId') as string;
    const contractNumber = sessionStorage.getItem('contractNumber') as string;
    const payLoad = new FormData();

    const filedata = {
        partnerId: partnerId,
        contractNumber: contractNumber,
        claimId: val?.claimId,
        fileTypeId: 1,
    };
    payLoad.append(
        'attachment',
        new Blob([JSON.stringify(filedata)], {
            type: 'application/json',
        })
    );
    payLoad.append('file', val?.fileInvoice?.file);
    // payLoad.append('file', data?.fileQuotation);
    // payLoad.append('file', data?.fileRepairInvoice);

    try {
        const response = yield Service.uploadFile(payLoad);
        if (response.status === 201) {
            // yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
            // yield put({ type: GET_CLAIM_HISTORY });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* uploadFileQuotation(data) {
    yield put({ type: SET_LOADING, val: true });
    const { val } = data;
    const partnerId = sessionStorage.getItem('partnerId') as string;
    const contractNumber = sessionStorage.getItem('contractNumber') as string;
    const payLoad = new FormData();

    const filedata = {
        partnerId: partnerId,
        contractNumber: contractNumber,
        claimId: val?.claimId,
        fileTypeId: 1,
    };
    payLoad.append(
        'attachment',
        new Blob([JSON.stringify(filedata)], {
            type: 'application/json',
        })
    );
    // payLoad.append('file', data?.fileInvoice);
    payLoad.append('file', val?.fileQuotation?.file);
    // payLoad.append('file', data?.fileRepairInvoice);

    try {
        const response = yield Service.uploadFile(payLoad);
        if (response.status === 201) {
            // yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
            // yield put({ type: GET_CLAIM_HISTORY });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
export function* uploadFileRepairInvoice(data) {
    yield put({ type: SET_LOADING, val: true });
    const { val } = data;
    const partnerId = sessionStorage.getItem('partnerId') as string;
    const contractNumber = sessionStorage.getItem('contractNumber') as string;
    const payLoad = new FormData();

    const filedata = {
        partnerId: partnerId,
        contractNumber: contractNumber,
        claimId: val?.claimId,
        fileTypeId: 1,
    };
    payLoad.append(
        'attachment',
        new Blob([JSON.stringify(filedata)], {
            type: 'application/json',
        })
    );
    // payLoad.append('file', data?.fileInvoice);
    // payLoad.append('file', data?.fileQuotation);
    payLoad.append('file', val?.fileRepairInvoice?.file);

    try {
        const response = yield Service.uploadFile(payLoad);
        if (response.status === 201) {
            // yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
            // yield put({ type: GET_CLAIM_HISTORY });
        } else {
            yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: response?.status } });
        }
    } catch (error) {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'verify_errors', errorCode: error?.response?.status } });
    } finally {
        yield put({ type: SET_LOADING, val: false });
    }
}
