/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from '@src/api/api';
import ClaimCard from '@src/components/Ui/ClaimCard';
import { setNotification } from '@src/components/Ui/Notification';
import RootState from '@src/interfaces/RootState';
import { Box, Grid, Typography } from '@mui/material';
import { GET_CLAIM_HISTORY } from '@src/redux/claim/actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ClaimHistory = () => {
    const userData = useSelector((state: RootState) => state?.form?.userform?.values);
    const claims = useSelector((state: RootState) => state?.claim?.claimHistory);

    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();

    // useEffect(() => {
    //     // generateClaimsView(claimList);
    //     dispatchStore(reset('userform'));
    // }, [editableClaimId]);

    useEffect(() => {
        dispatchStore({ type: GET_CLAIM_HISTORY });
    }, []);

    return (
        <Box sx={{ position: 'relative', minHeight: '100vh', padding: '1rem' }}>
            <Grid container justifyContent={'center'} sx={{ margin: '1rem 0' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                    {/* <CardMedia sx={{ maxHeight: '40px', width: '100%', objectFit: 'contain' }} component="img" alt="green iguana" height="100%" image="/img/alternatelogo.png" /> */}
                    <Typography sx={{ fontSize: '32px', margin: '.5rem', textTransform: 'uppercase', fontWeight: 'bolder' }} variant="h2">
                        {t('claim_list.title')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} spacing={2}>
                {claims?.map((claim, index) => {
                    return (
                        <Grid item xs={10} md={5} key={index}>
                            <ClaimCard claim={claim} />
                        </Grid>
                    );
                })}
            </Grid>
            {/* {claims && <Grid container>{claimView}</Grid>}
            {(!claims || claims.length == 0) && (
                <Grid container>
                    <h3>{t('claim_list.no_claim')}</h3>
                </Grid>
            )} */}
        </Box>
    );
};

export default ClaimHistory;
