/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable prefer-const */
import AccountIcon from 'mdi-react/AccountIcon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardMedia, Grid, Typography } from '@mui/material';
import ContractCard from '@src/components/Ui/ContractCard';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Profile = () => {
    const { t } = useTranslation('common');
    const contract = JSON.parse(sessionStorage.getItem('contract') as string);
    const account = JSON.parse(sessionStorage.getItem('account') as string);

    return (
        <React.Fragment>
            <Grid container justifyContent={'center'} sx={{ margin: '1rem 0' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                    <CardMedia sx={{ maxHeight: '40px', width: '100%', objectFit: 'contain' }} component="img" alt="green iguana" height="100%" image="/img/alternatelogo.png" />
                    <Typography sx={{ textWrap: 'nowrap', fontSize: '32px', margin: '.5rem', textTransform: 'uppercase', fontWeight: 'bolder' }} variant="h2">
                        {t('profile.title')}
                    </Typography>
                </Grid>
            </Grid>
            {contract && (
                <Grid container sx={{ width: '90%', margin: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} md={6} sx={{ padding: '1rem', height: '100%' }}>
                        <ContractCard contract={contract} account={account} />
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default Profile;
