import { takeLatest } from 'redux-saga/effects';
import {
    CLAIM_ADD,
    GET_CLAIM_HISTORY,
    UPDATE_CLAIM_INVOICE,
    UPLOAD_FILE_INOVICE,
    UPLOAD_FILE_QUOTATION,
    UPLOAD_FILE_REPAIR_INOVICE,
    UPDATE_ACCOUNT,
    SET_COST_DETAIL,
    claimAdd,
    claimHistory,
    updateClaimInvoice,
    uploadFileInvoice,
    uploadFileQuotation,
    uploadFileRepairInvoice,
    updateAccount,
    setCostDetail,
} from './actions';

export default [
    takeLatest(CLAIM_ADD, claimAdd),
    takeLatest(GET_CLAIM_HISTORY, claimHistory),
    takeLatest(UPDATE_CLAIM_INVOICE, updateClaimInvoice),
    takeLatest(UPLOAD_FILE_INOVICE, uploadFileInvoice),
    takeLatest(UPLOAD_FILE_QUOTATION, uploadFileQuotation),
    takeLatest(UPLOAD_FILE_REPAIR_INOVICE, uploadFileRepairInvoice),
    takeLatest(UPDATE_ACCOUNT, updateAccount),
    takeLatest(SET_COST_DETAIL, setCostDetail),
];
