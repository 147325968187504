import React, { ReactNode } from 'react';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import { InputAdornment, SvgIconProps, ThemeProvider } from '@mui/material';
import { theme } from './theme';

type TextFieldProps = {
    onChange?: (...event: any[]) => void;
    label: string;
    value: string;
    input?: Record<string, Record<string, any> | string | number>;
    variant?: TextFieldVariants | undefined;
    icon?: ReactNode | null;
    // isRequired?: boolean;
    error?: any;
    disabled?: boolean;
    helperText?: string | null | undefined;
};
const RenderTextField = ({ error, disabled, icon, helperText, onChange, label, input, value, variant, ...custom }: TextFieldProps) => {
    return (
        <ThemeProvider theme={theme}>
            <TextField
                // input={input?.value.replace(regex, '')} // TODO Tesztelni
                onChange={onChange}
                variant={variant}
                fullWidth
                disabled={disabled}
                value={value}
                label={label}
                error={error}
                helperText={helperText}
                placeholder={label}
                InputProps={
                    icon
                        ? {
                              startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                          }
                        : undefined
                }
                {...input}
                {...custom}
            />
        </ThemeProvider>
    );
};

export default RenderTextField;
