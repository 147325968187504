import { Claim } from '@src/api/interfaces/Claim';
import { RESET_CLAIM, SAVE_CLAIM_FORM, SET_CLAIM_HISTORY, SET_CLAIM_NUMBER } from './actions';

const initialState = {
    claimForm: null,
    claimNumber: null,
    claimHistory: null,
};

export default function claimReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CLAIM_FORM: {
            return {
                ...state,
                claimForm: action.val,
            };
        }
        case SET_CLAIM_NUMBER: {
            return {
                ...state,
                claimNumber: action.val,
            };
        }
        case SET_CLAIM_HISTORY: {
            return {
                ...state,
                claimHistory: action.val,
            };
        }
        case RESET_CLAIM: {
            return initialState;
        }
        default:
            return state;
    }
}
