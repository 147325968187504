import { PERFORM_LOGIN, VERIFY_CODE } from '@src/redux/auth/actions';
import store from '@src/redux/store';

type Login = {
    authenticationType?: string;
    serialNumber: string;
    email: string;
    code?: string;
};
export class LoginModel implements Login {
    authenticationType?: string;
    serialNumber: string;
    email: string;
    code?: string;

    constructor(data) {
        this.authenticationType = 'EMAIL_2FACTOR';
        this.serialNumber = data.serialNumber;
        this.email = data.email;
        this.code = data?.confirmationId;
    }
    public performLogin() {
        store.dispatch({ type: PERFORM_LOGIN, val: this });
    }
    public verifyCode() {
        store.dispatch({ type: VERIFY_CODE, val: this });
    }
}
