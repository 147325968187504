export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
    const tokenDatas = JSON.parse(jsonPayload);
    // sessionStorage.setItem('age', tokenDatas?.AGE);
    sessionStorage.setItem('contractNumber', tokenDatas?.CONTRACT_NUMBER);
    sessionStorage.setItem('partnerId', tokenDatas?.PARTNER_ID);
    return JSON.parse(jsonPayload);
}
