import { Box, Button, CardHeader, Grid } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import RenderTextField from '../Form/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeviceIcon from 'mdi-react/CellphoneLinkIcon';
const ProductCard = ({ device, contract }) => {
    const { t } = useTranslation('common');
    const { handleSubmit, reset, control, setValue } = useForm<any>();
    return (
        <Box sx={{ border: '1px solid rgba(0,0,0,0.175)', borderRadius: '5px' }}>
            <CardHeader sx={{ backgroundColor: '#dddd', textTransform: 'uppercase' }} avatar={<DeviceIcon />} title={t('dashboard.device')} />
            <Grid container justifyContent={'center'} sx={{ padding: '1rem 0' }} spacing={1}>
                <Grid item xs={10}>
                    <Controller
                        name="receiptNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField
                                error={!!error}
                                disabled={true}
                                helperText={error ? t('validations.required') : null}
                                onChange={onChange}
                                value={device?.group?.name ?? t('dashboard.unknown')}
                                label={t('dashboard.groupname')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                    <Controller
                        name="receiptNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField
                                error={!!error}
                                disabled={true}
                                helperText={error ? t('validations.required') : null}
                                onChange={onChange}
                                value={device.brand?.name ?? t('dashboard.unknown')}
                                label={t('dashboard.device_brand')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                    <Controller
                        name="receiptNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField
                                error={!!error}
                                disabled={true}
                                helperText={error ? t('validations.required') : null}
                                onChange={onChange}
                                value={device?.model?.name ?? t('dashboard.unknown')}
                                label={t('dashboard.device_model')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                    <Controller
                        name="receiptNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField
                                error={!!error}
                                disabled={true}
                                helperText={error ? t('validations.required') : null}
                                onChange={onChange}
                                value={device?.serialNumber ?? t('dashboard.unknown')}
                                label={t('dashboard.device_id')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={10} sx={{ margin: '.5rem 0' }}>
                    <Controller
                        name="receiptNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField
                                error={!!error}
                                disabled={true}
                                helperText={error ? t('validations.required') : null}
                                onChange={onChange}
                                value={contract?.contractNumber ?? t('dashboard.unknown')}
                                label={t('dashboard.contract_number')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={10} lg={5} sx={{ margin: '.5rem 0' }}>
                    <Controller
                        name="receiptNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField
                                error={!!error}
                                disabled={true}
                                helperText={error ? t('validations.required') : null}
                                onChange={onChange}
                                value={new Intl.DateTimeFormat('en-GB', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }).format(Date.parse(contract?.salesDate))}
                                label={t('dashboard.purchase_date')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={10} lg={5} sx={{ margin: '.5rem 0' }}>
                    <Controller
                        name="receiptNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField
                                error={!!error}
                                disabled={true}
                                helperText={error ? t('validations.required') : null}
                                onChange={onChange}
                                value={new Intl.DateTimeFormat('en-GB', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                }).format(Date.parse(contract?.contractExpireDate))}
                                label={t('dashboard.expire_date')}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={10} sx={{ margin: '.5rem 0' }} className="dashboard__action-button">
                    <Link to={`/newclaim`}>
                        <Button
                            className="btn__primary"
                            sx={{ height: '55px' }}
                            onClick={() => {
                                sessionStorage.setItem('selectedContract', JSON.stringify(contract));
                            }}
                        >
                            {t('layout.claims_new')}
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProductCard;
