import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as uiActions from '@src/redux/ui/actions';
import RootState from '@src/interfaces/RootState';
import { Box, FormControl, InputLabel, MenuItem, Select, ThemeProvider } from '@mui/material';
import { theme } from '../Form/theme';

const SelectLanguage = () => {
    const [languageList, setLanguageList] = useState([
        { name: 'EN', value: 'en', label: 'EN' },
        { name: 'FR', value: 'fr', label: 'FR' },
        { name: 'NL', value: 'nl', label: 'NL' },
    ]);
    const dispatchAction = useDispatch();
    const currentLng = useSelector((state: RootState) => state?.ui?.language);

    const { i18n } = useTranslation('common');
    // const language = currentLng ? currentLng : 'en';
    const { t } = useTranslation('common');

    const changeLng = (e) => {
        dispatchAction({ type: uiActions.SET_LANGUAGE, val: e.target.value });
        i18n.changeLanguage(e.target.value);
        localStorage.setItem('language', e.target.value);
    };

    return (
        <ThemeProvider theme={theme}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t('login.select_language')}</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={currentLng} label={t('login.select_language')} onChange={changeLng}>
                    {languageList.map((item, index) => {
                        return (
                            <MenuItem key={index} value={item.value} sx={{}}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
};

export default React.memo(SelectLanguage);
