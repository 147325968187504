import { getClaimStatus } from '@src/components/Utility/util';
import { ClaimCostDetails, Claim, StatusHistory } from '../interfaces/Claim';
import dayjs, { Dayjs } from 'dayjs';

export class ClaimHistoryModel {
    clm_id: string | number;
    breakdown_date: Dayjs | string;
    clm_status: string | number;
    clm_status_id: string | number;
    clm_description: string;
    con_number: string | number;
    // bank_account_number: string | number;
    constructor(data) {
        this.clm_id = data.claimId;
        this.breakdown_date = dayjs(data.claimDate).format('YYYY-MM-DD');
        this.clm_status = getClaimStatus(data.status.id);
        this.clm_status_id = data.status.id;
        this.clm_description = data.description;
        this.con_number = data.contractNumber;
        // this.bank_account_number = data.alternateInvoiceReference;
    }
}

export type ClaimType = {
    clm_id?: number;
    prt_id: string;
    clm_type: string;
    con_number: string;
    clm_description: string;
    clm_status: string;
    user_name: string;
    // clm_brief_desc_id: number | null;
    breakdown_date: string | Date;
    breakdownDate: string | Date;
};

class ClaimModel implements Partial<Claim> {
    clm_date: Dayjs | string;
    breakdown_date: Dayjs | string;
    clm_description: string;
    clm_paymentmethod: string;
    clm_status: number;
    user_name: string;
    clm_type: string;
    invoice_date?: Dayjs | string;
    prt_id: string;
    invoice_amount?: string;
    con_number: string;
    claimCostDetails: ClaimCostDetails[];
    statusHistory: StatusHistory[];
    bank_account?: string;
    fileQuotation?: any;
    fileInvoice?: any;
    fileRepairInvoice?: any;
    customer_phone?: string;
    constructor(data: Claim, contractData: { con_number: string; partner: { prt_id: string } }) {
        this.breakdown_date = dayjs(data.breakdownDate).format('YYYY-MM-DD');
        this.invoice_date = dayjs(data.invoiceDate).format('YYYY-MM-DD');
        this.clm_date = dayjs(data.breakdown_date).format('YYYY-MM-DD');
        this.clm_description = data.claimDescription;
        this.customer_phone = data.customerPhone;
        this.invoice_amount = data.invoiceAmount;
        this.clm_paymentmethod = 'B';
        this.bank_account = data.bankAccount;
        this.clm_status = 30;
        this.user_name = contractData.con_number;
        this.clm_type = 'M';
        this.prt_id = contractData.partner.prt_id;
        this.con_number = contractData.con_number;
        this.claimCostDetails = [new ClaimCostDetailsModel(data, contractData)];
        this.statusHistory = [new StatusHistoryModel(contractData)];
        this.fileQuotation = data.fileQuotation;
        this.fileInvoice = data.fileInvoice;
        this.fileRepairInvoice = data.fileRepairInvoice;
    }
    public summary() {
        return {
            customer_phone: `+${this.customer_phone}`,
            breakdown_date: this.breakdown_date,
            invoice_date: this.invoice_date,
            file_quotation: this.fileQuotation?.name,
            file_invoice: this.fileInvoice?.name,
            file_repair_invoice: this.fileRepairInvoice?.name,
            invoice_amount: this.invoice_amount,
        };
    }
    public compilePayload() {
        const payload = new FormData();
        const thisClaimWithoutFiles = { ...this };

        if (this.fileInvoice) {
            payload.append('files', this.fileInvoice?.file);
        }
        if (this.fileQuotation) {
            payload.append('files', this.fileQuotation?.file);
        }
        if (this.fileRepairInvoice) {
            payload.append('files', this.fileRepairInvoice?.file);
        }
        delete thisClaimWithoutFiles.bank_account;
        delete thisClaimWithoutFiles.invoice_amount;
        delete thisClaimWithoutFiles.invoice_date;
        delete thisClaimWithoutFiles.customer_phone;
        delete thisClaimWithoutFiles.fileQuotation;
        delete thisClaimWithoutFiles.fileInvoice;
        delete thisClaimWithoutFiles.fileRepairInvoice;
        payload.append(
            'claim',
            new Blob([JSON.stringify(thisClaimWithoutFiles)], {
                type: 'application/json',
            })
        );
        /** Log out FormData */
        // const entries = [...payload.entries()];
        // const data = Object.fromEntries(entries);
        return payload;
    }
}

class ClaimCostDetailsModel implements ClaimCostDetails {
    type_id: number;
    tentative_value: number;
    real_value: number;
    repairer_id: number;
    user_name: string;
    last_edited_date: Dayjs | string;
    invoice_reference: string;
    invoice_date: Dayjs | string;
    invoice_reception_date: Dayjs | string;
    constructor(data: Claim, contractData: any) {
        this.type_id = 4;
        this.tentative_value = parseFloat(data?.invoiceAmount).toFixed(2);
        this.real_value = parseFloat(data?.invoiceAmount).toFixed(2);
        this.repairer_id = 1;
        this.user_name = contractData.customerName;
        this.last_edited_date = dayjs(new Date()).format('DD-MM-YYYY');
        this.invoice_reference = data.bankAccount as string;
        this.invoice_date = dayjs(data.invoiceDate).format('DD-MM-YYYY');
        this.invoice_reception_date = dayjs(new Date()).format('DD-MM-YYYY');
    }
}

class StatusHistoryModel implements StatusHistory {
    prt_id: string;
    change_date: Dayjs | string;
    clm_status: number;
    user_name: string;
    constructor(contractData) {
        (this.prt_id = contractData.partner.prt_id), (this.change_date = dayjs(new Date())).format('DD-MM-YYYY'), (this.clm_status = 5), (this.user_name = contractData.customername);
    }
}
export default ClaimModel;
