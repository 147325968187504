/* eslint-disable react-hooks/rules-of-hooks */
import { CardMedia, Grid, Typography } from '@mui/material';
import ContractCard from '@src/components/Ui/ContractCard';
import ProductCard from '@src/components/Ui/ProductCard';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Inputs = {
    receiptNumber: string;
    email: string;
    confirmationId?: string;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Dashboard = () => {
    // const [userData, setUserData] = useState<Record<string, any>>({});
    // const [brand, setBrand] = useState<Record<string, any>>({});
    // const [group, setGroup] = useState<Record<string, any>>({});
    const [contract, setContract] = useState<any>();
    const [device, setDevice] = useState<any>();
    const [account, setAccount] = useState<any>();
    const storedAccount = JSON.parse(sessionStorage.getItem('account') as string);
    const { t } = useTranslation('common');

    useEffect(() => {
        setAccount(JSON.parse(sessionStorage.getItem('account') as string));
    }, [storedAccount]);

    useEffect(() => {
        //setUserData(JSON.parse(sessionStorage.getItem('contract')!)[0]);
        setContract(JSON.parse(sessionStorage.getItem('contract') as string));
        setDevice(JSON.parse(sessionStorage.getItem('device') as string));
        setAccount(JSON.parse(sessionStorage.getItem('account') as string));
    }, []);
    return (
        <React.Fragment>
            <Grid container justifyContent={'center'} sx={{ margin: '1rem 0' }}>
                <Grid item sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                    <CardMedia sx={{ maxHeight: '40px', width: '100%', objectFit: 'contain' }} component="img" alt="green iguana" height="100%" image="/img/alternatelogo.png" />
                    <Typography sx={{ fontSize: '32px', margin: '.5rem', textTransform: 'uppercase', fontWeight: 'bolder' }} variant="h2">
                        {t('dashboard.title')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ width: '90%', margin: 'auto', display: 'flex' }}>
                {contract && (
                    <Grid item xs={12} md={6} sx={{ padding: '1rem', height: '100%' }}>
                        <ContractCard contract={contract} account={account} />
                    </Grid>
                )}

                {device && (
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <ProductCard device={device} contract={contract} />
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
};

export default Dashboard;
