import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import RenderTextField from '@src/components/Form/TextField';
import RootState from '@src/interfaces/RootState';
import { CLAIM_ADD } from '@src/redux/claim/actions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const Summary = () => {
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const claim = useSelector((state: RootState) => state?.claim.claimForm);
    // const claimSummary = useSelector((state: RootState) => state?.claim.claimForm.summary());
    const responseClaimNumber = useSelector((state: RootState) => state?.claim.claimNumber);
    const navigate = useNavigate();

    // phone number
    // date of incident
    // date of invoice
    // files - name
    // invoice amount
    // bank account number

    useEffect(() => {
        if (responseClaimNumber !== null) {
            navigate('/success');
        }
    }, [responseClaimNumber]);
    const submitClaim = () => {
        dispatchStore({ type: CLAIM_ADD, val: claim });
    };

    useEffect(() => {
        if (!claim) {
            navigate('/dashboard');
        }
    }, [claim]);
    return (
        <Box sx={{ position: 'relative', minHeight: '100vh', padding: '5%' }}>
            <CardMedia
                sx={{ opacity: '.3', zIndex: '-1', padding: '20%', width: '100%', objectFit: 'contain', position: 'absolute', bottom: '0', left: '0' }}
                component="img"
                height="100%"
                image="/img/summary.svg"
            />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitClaim();
                }}
            >
                {/* <Box sx={{}}> */}
                {/* <CardMedia sx={{ zIndex: '-1', width: '100%', objectFit: 'contain', position: 'absolute', top: '0', left: '0' }} component="img" height="100%" image="/img/summary.svg" /> */}
                {/* </Box> */}
                <Grid container justifyContent={'center'} sx={{ margin: '1rem 0' }}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' } }}>
                        <Typography sx={{ fontSize: '32px', margin: '.5rem', textTransform: 'uppercase', fontWeight: 'bolder' }} variant="h2">
                            {t('summary.title')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ width: '90%', margin: 'auto' }}>
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <RenderTextField disabled label={t(`summary.customer_phone`)} value={claim?.customer_phone} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <RenderTextField disabled label={t(`summary.breakdown_date`)} value={claim?.breakdown_date as string} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <RenderTextField disabled label={t(`summary.invoice_date`)} value={claim?.invoice_date} />
                    </Grid>
                    {claim?.fileQuotation ? (
                        <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                            <RenderTextField disabled label={t(`summary.file_quotation`)} value={claim?.fileQuotation?.name} />
                        </Grid>
                    ) : (
                        ''
                    )}
                    {claim?.fileInvoice ? (
                        <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                            <RenderTextField disabled label={t(`summary.file_invoice`)} value={claim?.fileInvoice?.name} />
                        </Grid>
                    ) : (
                        ''
                    )}
                    {claim?.fileRepairInvoice ? (
                        <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                            <RenderTextField disabled label={t(`summary.file_repair_invoice`)} value={claim?.fileRepairInvoice?.name} />
                        </Grid>
                    ) : (
                        ''
                    )}
                    <Grid item xs={12} md={6} sx={{ padding: '1rem' }}>
                        <RenderTextField disabled label={t(`summary.invoice_amount`)} value={claim?.invoice_amount as string} />
                    </Grid>
                </Grid>
                <Grid container sx={{ width: '90%', margin: 'auto' }} justifyContent={'space-between'}>
                    <Grid item xs={10} md={5} sx={{ padding: '1rem' }}>
                        <Link to="/newclaim">
                            <Button className="btn__secondary">{t('layout.back')}</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={10} md={5} sx={{ padding: '1rem', display: 'flex', justifyContent: 'end' }}>
                        <Button className="btn__primary" type="submit">
                            {t('claim.submit')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default Summary;
