import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import RenderTextField from '@src/components/Form/TextField';
import { Button, Grid, TextField } from '@mui/material';
import SelectLanguage from './SelectLanguage';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { LoginModel } from '@src/api/models/Login';
import { useDispatch, useSelector } from 'react-redux';
import RootState from '@src/interfaces/RootState';
import { useNavigate } from 'react-router-dom';
import { VERIFICATION_IN_PROGRESS } from '@src/redux/auth/actions';

type Inputs = {
    serialNumber: string;
    email: string;
    confirmationId?: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PhoneNForm2F = (props) => {
    const [counterElement, setCounterElement] = useState<Array<JSX.Element>>();
    const [codeExpired, setCodeExpired] = useState<boolean>(false);

    const verifyLogin = useSelector((state: RootState) => state?.auth?.verifyLogin);
    const signedIn = useSelector((state: RootState) => state?.auth?.signedIn);
    const navigate = useNavigate();
    const dispatchAction = useDispatch();

    const { t } = useTranslation('common');

    // React-Hook-Form
    const { handleSubmit, reset, control, setValue } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const credentials = new LoginModel(data);
        if (verifyLogin) credentials.verifyCode();
        else credentials.performLogin();
    };

    useEffect(() => {
        if (signedIn === true) {
            dispatchAction({ type: VERIFICATION_IN_PROGRESS, val: false });
            navigate('/dashboard');
        }
    }, [signedIn]);

    const securityExpired = useCallback(() => {
        reset();
        dispatchAction({ type: VERIFICATION_IN_PROGRESS, val: false });
    }, []);

    useEffect(() => {
        if (verifyLogin === true) {
            setCounterElement([
                <div key={'exp'}>
                    <span className="login__code-expiry">{t('login.code-expires')}:</span>
                    <Countdown date={Date.now() + 1000 * 30 * 10} onComplete={securityExpired} renderer={(props) => <div className="security__expire">{props.total / 1000}</div>} />
                </div>,
            ]);
        }
    }, [verifyLogin]);
    // 444141962
    // gabor.nagy@ewmgroup.com

    // useEffect(() => {
    //     setValue('receiptNumber', '444144950');
    //     setValue('email', 'zsolt.nalhi@ewmgroup.com');
    // }, []);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid item sx={{ width: '100%', margin: '0.5rem 0' }}>
                    <SelectLanguage />
                </Grid>
                <Grid item sx={{ width: '100%', margin: '0.5rem 0', justifyContent: 'center' }}>
                    <Controller
                        name="serialNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField error={!!error} helperText={error ? t('validations.required') : null} onChange={onChange} value={value || ''} label={t('login.serial_number')} />
                        )}
                    />
                </Grid>
                <Grid item sx={{ width: '100%', margin: '0.5rem 0' }}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <RenderTextField
                                error={!!error}
                                helperText={error ? t('validations.required') : null}
                                onChange={onChange}
                                value={value || ''}
                                label={t('login.email')}
                                disabled={props.isFirstStepComplete}
                            />
                        )}
                    />
                </Grid>
                {verifyLogin && (
                    <Grid item sx={{ width: '100%', margin: '0.5rem 0' }}>
                        <Controller
                            name="confirmationId"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RenderTextField
                                    error={!!error}
                                    helperText={error ? t('validations.required') : null}
                                    onChange={onChange}
                                    value={value || ''}
                                    label={t('login.security_code')}
                                    disabled={props.isFirstStepComplete}
                                />
                            )}
                        />
                    </Grid>
                )}
                <Grid container>
                    {verifyLogin && (
                        <Grid item xs={12}>
                            {counterElement}
                        </Grid>
                    )}
                </Grid>
                <Grid item sx={{ width: '100%', margin: '0.5rem 0' }}>
                    <Button
                        // onClick={handleSubmit(onSubmit)}
                        className="btn__primary"
                        fullWidth
                        type="submit"
                    >
                        {t('login.login')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default PhoneNForm2F;
