import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import PrivateRoute from '@src/contexts/auth/protectedRoute';
import MainWrapper from '@src/layout/MainWrapper/MainWrapper';

import Dashboard from '@src/pages/dashboard';
import NewClaim from '../pages/claims/new';
import ClaimHistory from '../pages/claims/list';
import Profile from '../pages/profile/index';
import LogIn from '../pages/index';
import Contact from '../pages/contact/index';
import Summary from '@src/pages/summary';
import Success from '@src/pages/success';
// import LogIn from '../pages/login';

// const LogIn = lazy(() => import('../pages/index'));
// const Contact = lazy(() => import('../pages/contact/index'));
// const Dashboard = lazy(() => import('../pages/dashboard'));
// const NewClaim = lazy(() => import('../pages/claims/new'));
// const Profile = lazy(() => import('../pages/profile/index'));
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<MainWrapper />}>
            <Route index element={<LogIn />} />
            <Route path="/dashboard" element={<PrivateRoute />}>
                <Route index element={<Dashboard />} />
            </Route>
            <Route path="/newclaim" element={<PrivateRoute />}>
                <Route index element={<NewClaim />} />
            </Route>
            <Route path="/summary" element={<PrivateRoute />}>
                <Route index element={<Summary />} />
            </Route>
            <Route path="/success" element={<PrivateRoute />}>
                <Route index element={<Success />} />
            </Route>
            <Route path="/claim-history" element={<PrivateRoute />}>
                <Route index element={<ClaimHistory />} />
            </Route>
            <Route path="/profile" element={<PrivateRoute />}>
                <Route index element={<Profile />} />
            </Route>
            <Route path="/contact" element={<Contact />} />
        </Route>
    )
);
// <MainWrapper>
//     <main className="profile__container">
//         <Suspense fallback={<LoadingScreen forceLoading={true} />}>
//             <Routes>
//                 <Route path="/dashboard" element={<PrivateRoute />}>
//                     <Route index element={<Dashboard />} />
//                 </Route>
//                 <Route path="/newclaim" element={<PrivateRoute />}>
//                     <Route index element={<NewClaim />} />
//                 </Route>
//                 <Route path="/claims/history" element={<PrivateRoute />}>
//                     <Route index element={<ClaimHistory />} />
//                 </Route>
//                 <Route path="/profile" element={<PrivateRoute />}>
//                     <Route index element={<Profile />} />
//                 </Route>
//                 <Route path="/contact" element={<Contact />} />
//                 <Route path="/" element={<LogIn />} />
//             </Routes>
//         </Suspense>
//     </main>
// </MainWrapper>
//     );
// };

// export default Router;
