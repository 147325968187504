import { takeLatest } from 'redux-saga/effects';
import {
    LOG_OUT,
    PERFORM_LOGIN,
    REFRESH_TOKEN,
    VERIFY_CODE,
    GET_CONTRACT,
    GET_DEVICE,
    GET_ACCOUNT,
    GET_DAMAGE_SUBTYPES,
    logout,
    performLogin,
    refreshToken,
    verifyCode,
    getContract,
    getDevice,
    getAccount,
    getDamageSubtypes,
} from './actions';

export default [
    takeLatest(PERFORM_LOGIN, performLogin),
    takeLatest(VERIFY_CODE, verifyCode),
    takeLatest(REFRESH_TOKEN, refreshToken),
    takeLatest(LOG_OUT, logout),
    takeLatest(GET_CONTRACT, getContract),
    takeLatest(GET_DEVICE, getDevice),
    takeLatest(GET_ACCOUNT, getAccount),
    takeLatest(GET_DAMAGE_SUBTYPES, getDamageSubtypes),
];
