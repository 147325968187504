import { put } from 'redux-saga/effects';
import { SET_LOADING } from '../ui/actions';
import Service from '@src/api/api';
import { parseJwt } from '@src/contexts/auth/tokenParser';
import { setNotification } from '@src/components/Ui/Notification';
// import { useContext } from 'react';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_DAMAGE_SUBTYPES = 'GET_DAMAGE_SUBTYPES';
export const GET_DEVICE = 'GET_DEVICE';
export const LOG_OUT = 'LOG_OUT';
export const PERFORM_LOGIN = 'PERFORM_LOGIN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SIGNED_IN = 'SIGNED_IN';
export const VERIFY_CODE = 'VERIFY_CODE';
export const VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS';

export function* performLogin(data) {
    yield put({ type: SET_LOADING, val: true });

    const { val: payload } = data;

    try {
        const response = yield Service.performLogin(payload);

        if (response?.status === 200) {
            yield put({ type: VERIFICATION_IN_PROGRESS, val: true });
        }
    } catch (error) {
        yield put({
            type: 'SET_ERROR_OBJECT',
            val: {
                dictionaryObject: 'verify_errors',
                errorCode: error?.response?.status,
                debugMessage: error?.response?.data?.message,
            },
        });
    }
    yield put({ type: SET_LOADING, val: false });
}

export function* verifyCode(data) {
    yield put({ type: SET_LOADING, val: true });

    const { val: payload } = data;
    const payLoad = {
        code: payload?.code,
        email: payload?.email,
        serialNumber: payload?.serialNumber,
    }
    try {
        const response = yield Service.verifyCode(payLoad);

        if (response?.status === 200) {
            sessionStorage.setItem('token_alternate', `Bearer ${response?.headers?.authorization}`);
            const tokenDatas = parseJwt(response?.headers?.authorization);
            sessionStorage.setItem('partnerId', tokenDatas?.PARTNER_ID);
            sessionStorage.setItem('contractNumber', tokenDatas?.CONTRACT_NUMBER);
            yield put({ type: GET_CONTRACT, val: true });
            yield put({ type: GET_ACCOUNT, val: true });
            yield put({ type: GET_DAMAGE_SUBTYPES, val: true });
            // yield put({ type: SIGNED_IN, val: true });
        }
    } catch (error) {
        yield put({
            type: 'SET_ERROR_OBJECT',
            val: {
                dictionaryObject: 'verify_errors',
                errCode: error?.response?.status,
            },
        });
    }
    yield put({ type: SET_LOADING, val: false });
}

export function* getContract() {
    yield put({ type: SET_LOADING, val: true });
    const partnerId = sessionStorage.getItem('partnerId') as string;
    const contractNumber = sessionStorage.getItem('contractNumber') as string;

    try {
        const response = yield Service.getContract(partnerId, contractNumber);

        if (response?.status === 200) {
            sessionStorage.setItem('contract', JSON.stringify(response?.data));
            sessionStorage.setItem('deviceId', JSON.stringify(response?.data?.deviceId));
            yield put({ type: GET_DEVICE, val: true });

            // yield put({ type: SIGNED_IN, val: true });
        }
    } catch (error) {
        yield put({
            type: 'SET_ERROR_OBJECT',
            val: {
                dictionaryObject: 'verify_errors',
                errCode: error?.response?.status,
            },
        });
    }
    yield put({ type: SET_LOADING, val: false });
}
export function* getAccount() {
    yield put({ type: SET_LOADING, val: true });
    const partnerId = sessionStorage.getItem('partnerId') as string;
    const contractNumber = sessionStorage.getItem('contractNumber') as string;

    try {
        const response = yield Service.getAccount(partnerId, contractNumber);

        if (response?.status === 200) {
            sessionStorage.setItem('account', JSON.stringify(response?.data));
        }
    } catch (error) {
        yield put({
            type: 'SET_ERROR_OBJECT',
            val: {
                dictionaryObject: 'verify_errors',
                errCode: error?.response?.status,
            },
        });
    }
    yield put({ type: SET_LOADING, val: false });
}
export function* getDamageSubtypes() {
    yield put({ type: SET_LOADING, val: true });

    try {
        const response = yield Service.getDamageSubTypes();

        if (response?.status === 200) {
            const filteredDamage = response?.data?.filter((data) => data.name == 'Default')[0];
            sessionStorage.setItem('defaultDamage', JSON.stringify(filteredDamage));
        }
    } catch (error) {
        yield put({
            type: 'SET_ERROR_OBJECT',
            val: {
                dictionaryObject: 'verify_errors',
                errCode: error?.response?.status,
            },
        });
    }
    yield put({ type: SET_LOADING, val: false });
}

export function* getDevice() {
    yield put({ type: SET_LOADING, val: true });
    const deviceId = sessionStorage.getItem('deviceId') as string;

    try {
        const response = yield Service.getDevice(deviceId);

        if (response?.status === 200) {
            sessionStorage.setItem('device', JSON.stringify(response?.data));

            yield put({ type: SIGNED_IN, val: true });
        }
    } catch (error) {
        yield put({
            type: 'SET_ERROR_OBJECT',
            val: {
                dictionaryObject: 'verify_errors',
                errCode: error?.response?.status,
            },
        });
    }
    yield put({ type: SET_LOADING, val: false });
}

export function* refreshToken() {
    try {
        const response = yield Service.refreshToken();
        sessionStorage.setItem('token_alternate', `Bearer ${response?.headers?.authorization}`);
        sessionStorage.setItem('checkNumber', response?.headers?.authorization);
    } catch (error) {
        yield put({
            type: 'SET_ERROR_OBJECT',
            val: {
                dictionaryObject: 'verify_errors',
                errCode: error?.response?.status,
            },
        });
    }
}

export function* logout() {
    yield put({ type: SET_LOADING, val: true });
    sessionStorage.clear();
    yield put({ type: SIGNED_IN, val: false });
    yield put({ type: SET_LOADING, val: false });
}
