import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TYPE, ReactNotifications } from 'react-notifications-component';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { handleGeneralError } from '@src/api/errorhandling/ErrorHandling';
import { GeneralErrors } from '@src/api/models/Error';
import LoadIndicator from '@src/components/Ui/Loader';
import { setNotification } from '@src/components/Ui/Notification';
import RootState from '@src/interfaces/RootState';
import SideBar from '../SideBar/SideBar';
import { Box, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Outlet } from 'react-router-dom';
interface MainWrapperProps {
    theme?: any;
    children?: any;
    lang?: string;
}

const MainWrapper: React.FC<MainWrapperProps> = ({ theme, children, lang }) => {
    const { t } = useTranslation('common');
    const dispatchStore = useDispatch();
    const errObject = useSelector((state: RootState) => state.err?.errorObject);
    const isLoading = useSelector((state: RootState) => state.ui?.isLoading);
    const isAuthenticated = useSelector((state: RootState) => state?.auth?.signedIn);

    // eslint-disable-next-line no-console

    useEffect(() => {
        if (errObject !== null) {
            errorHandling(errObject);
        }
    }, [errObject]);

    const errorHandling = (errObject) => {
        if (errObject?.errorCode === 401 && errObject?.dictionaryObject !== 'login_errors') {
            sessionStorage.removeItem('token_alternate');
            // history.replace('/');
        }
        let appearance: NOTIFICATION_TYPE = 'warning';
        let title = '';
        let target = '';

        switch (errObject?.errorCode) {
            case 200:
                appearance = 'success';
                title = t('error_handling.success');
                break;
            case 201:
                appearance = 'success';
                title = t('error_handling.success');
                break;
            case 401:
                appearance = 'warning';
                title = t('error_handling.warning');
                break;
            default:
                appearance = 'danger';
                title = t('error_handling.error');
        }
        if (errObject?.errorCode == 412 && errObject?.debugMessage && errObject?.debugMessage.includes('Contract does not exist with the given data')) {
            target = t(`${errObject?.dictionaryObject}.contract_does_not_exist`);
            setNotification(title, target, appearance);
        } else if (errObject?.errorCode == 412 && errObject?.debugMessage && errObject?.debugMessage.includes('Invalid IBAN number')) {
            target = t(`${errObject?.dictionaryObject}.invalid_iban`);
            setNotification(title, target, appearance);
        } else {
            target = t(`${errObject?.dictionaryObject}.${handleGeneralError(GeneralErrors, errObject?.errorCode)}`);
            setNotification(title, target, appearance);
        }
        dispatchStore({ type: 'SET_ERROR_OBJECT', val: null });
    };
    const [menuShow, setMenuShow] = useState(false);
    return (
        <Box component="main" className="main__container">
            <ReactNotifications />
            <Grid container justifyContent={'center'} sx={{ height: '100vh' }}>
                {isAuthenticated && (
                    <Grid
                        item
                        xs={10}
                        lg={2}
                        sx={{
                            width: '100%',
                            zIndex: '10',
                            position: { xs: 'absolute', lg: 'relative' },
                            left: '0',
                            transform: { xs: menuShow ? 'translateX(0)' : 'translateX(-100%)', xl: 'none' },
                            // transition: 'all .2s ease',
                        }}
                    >
                        <SideBar />
                    </Grid>
                )}
                {/* Menu Open/Close */}
                {isAuthenticated && (
                    <Grid
                        item
                        xs={1}
                        sx={{
                            display: { xl: 'none' },
                            zIndex: '11',
                            position: 'absolute',
                            left: { xs: 'calc(100% - 20px)', md: '5px' },
                            top: { xs: '20px', md: '5px' },
                            transform: { xs: 'translateX(-100%)', md: 'translateX(0)' },
                        }}
                    >
                        {menuShow ? (
                            <CloseIcon sx={{ cursor: 'pointer', fontSize: 40, color: { xs: 'black', md: 'white' } }} onClick={() => setMenuShow(false)} />
                        ) : (
                            <MenuIcon sx={{ cursor: 'pointer', fontSize: 40, color: 'black' }} onClick={() => setMenuShow(true)} />
                        )}
                    </Grid>
                )}
                <Grid item xs={12} lg={10}>
                    <div className="wrapper">
                        {isLoading && <LoadIndicator />}

                        <Outlet />
                        {/* {children} */}
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MainWrapper;
